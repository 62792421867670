<template>
  <div>
    <v-card class="custom-table" :class="{'hide-shadow': disableShadow}">
      <v-toolbar flat height="auto" class="custom-table-toolbar" v-if="!disableToolbar">
        <v-toolbar-items class="toolbar-left-items">
          <!-- Search Bar -->
          <Searchbar  v-if="enableSearch"
            :searchText.sync="searchText"
            :searchPlaceholder="searchPlaceholder"
            :searchType="searchType"
            :searchTypeOptions="searchTypeOptions"
            @search-clicked="$emit('search-clicked', $event)"
            @search-input="$emit('search-input', $event)"
            @search-type-changed="$emit('update:searchType', $event)"
            @search-clear="$emit('search-cleared')"
            :enableMultiTypeSearch="enableMultiTypeSearch"
            enabledClear
          ></Searchbar>

          <slot name="custom-toolbar-slot"></slot>
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items class="toolbar-right-items">
          <v-btn v-if="enableClearAll"
            depressed text small
            color="error"
            @click="clickClearAll()"
          >
            <v-icon class="mr-1" color="error">{{ icon.mdiClose }}</v-icon>
            Clear Search
          </v-btn>
          <v-btn v-if="enableRefresh"
            depressed text small
            color="success"
            @click="clickRefresh()"
          >
            <v-icon class="mr-1" color="success">{{ icon.mdiRefresh }}</v-icon>
            Reload
          </v-btn>
          <v-btn v-if="enableExport"
            depressed text small
            color="primary"
            @click="clickExport()"
          >
            <v-icon class="mr-1" color="primary">{{ icon.mdiTableArrowRight }}</v-icon>
            Export Data
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        v-model="selectedRow"
        class="custom-table"
        :class="{
          'input-datatable': isInputTable,
          'select-datatable': enableSelectToggle
        }"
        locale="zh-cn"
        :loading="processing"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="disablePagination ? -1 : pageLimit"
        :page="page"
        :server-items-length="disablePagination ? tableData.length : itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader || hideDefaultHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="!enableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="!enableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: 'Sorting',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: !disableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: 'Items per page',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: 'All',
          pageText: `Item ${tableData.length > 1 ? `${(page - 1) * pageLimit + 1}-${Math.min(page * pageLimit, itemTotal === -1 ? tableData.length : itemTotal)}` : (page - 1) * pageLimit + 1}`
        }"
        loading-text="Processing..."
        :no-data-text="tableEmptyText"
        :no-results-text="tableEmptyText"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
      >
        <!-- Top Pagination -->
        <template v-slot:top="{ pagination, options, updateOptions }" v-if="showTopSlot && !disablePagination">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="!disableFirstLastPage"
            :items-per-page-text="''"
            :page-text="`Total ${(itemTotal % pageLimit == 0 ? itemTotal / pageLimit : parseInt(itemTotal / pageLimit) + 1)} pages / ${itemTotal} Items`"
            @update:options="updateOptions"
          ></v-data-footer>
        </template>

        <!-- Header Item -->
        <template v-for="head in tableHeaders" v-slot:[`header.${head.value}`]="{ header }">
          <template v-if="header.headerInput === true">
            <FormInput
              :fieldValue.sync="headerInputFields[header.value]"
              hideDetails
              :placeholder="header.placeholder"
              dense
              dispatchUpdateOnChange
            ></FormInput>
          </template>
          <slot v-else 
            :name="`header.${header.value}`" 
            :header="header"
          ><span :style="`color: ${header.color || '#5e5669de'}; font-weight: ${header.fontWeight || 400}; white-space: nowrap;`">{{ header.text }}</span></slot>
        </template>

        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>

        <!-- Body item -->
        <template v-for="head in tableHeaders" v-slot:[`item.${head.value}`]="{ item, header }">
          <FormInput v-if="header.input === true"
            :fieldValue.sync="item[header.value]"
            hideDetails
            dense
            dispatchUpdateOnChange
            v-bind="header.itemInput"
            :disabled="disabledInput(item, header)"
            @input-value="inputUpdate(item.id, header.value, $event)"
          ></FormInput>
          <FormSelect v-else-if="header.isSelect === true"
            :fieldValue.sync="item[header.value]"
            hideDetails
            dense
            dispatchUpdateOnChange
            hideArrowIon
            v-bind="header.itemInput"
            :disabled="disabledInput(item, header)"
            @changed="inputUpdate(item.id, header.value, $event)"
          ></FormSelect>
          <slot v-else 
            :name="`item.${header.value}`" 
            :item="item" 
            :header="header"
          >
            <span v-if="item[header.value].indexOf('<sup>') > -1" v-html="item[header.value]"></span>
            <span v-else>{{$validate.DataValid(item[header.value]) ? (header.prefix || '') : '' }}{{ item[header.value] }}</span>
          </slot>
        </template>


        <!----------------------- Common ----------------------->
        <template v-slot:[`item.create_date`]="{ item }">
          <span v-if="$validate.DataValid(item.create_date)">{{ $Formatter.displayCreateDate(item.create_date, false) }}</span>
        </template>

        <template v-slot:[`item.preview_image_list`]="{ item }">
          <div class="pa-2">
            <ImageDisplay :imageList="item.preview_image_list"></ImageDisplay>
          </div>
        </template>

        <template v-slot:[`item.upload_image_list`]="{ item }">
          <div class="pa-2" v-if="item.upload_image_list !== undefined && item.upload_image_list !== null">
            <ImageUploader 
                :singleImage="false" 
                :imgList.sync="item.upload_image_list"
                :fileInputKey="`upload_image_${item.id}`"
                acceptFormat="image"
                noTextUploadBtn
            ></ImageUploader>
          </div>
        </template>

        <!----------------------- Action ----------------------->
        <template v-slot:[`item.actionViewDelete`]="{ item, header }">
          <v-btn depressed text color="secondary" small @click="clickActionView(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiEyeOutline }}</v-icon>
            {{ header.viewTitle || 'View' }}
          </v-btn>
          <v-btn class="ml-4" depressed text color="error" small @click="clickActionDelete(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiTrashCanOutline }}</v-icon>
            {{ header.deleteTitle || 'Delete'}}
          </v-btn>
        </template>

        <template v-slot:[`item.actionView`]="{ item, header }">
          <v-btn depressed text color="secondary" small @click="clickActionView(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiEyeOutline }}</v-icon>
            {{ header.viewTitle || 'View' }}
          </v-btn>
        </template>

        <template v-slot:[`item.actionDelete`]="{ item, header }">
          <v-btn class="ml-4" depressed text color="error" small @click="clickActionDelete(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiTrashCanOutline }}</v-icon>
            {{ header.deleteTitle || 'Delete'}}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
import {
  mdiEyeOutline,
  mdiCheck,
  mdiClose,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiTableArrowRight,
  mdiTrashCanOutline,
} from '@mdi/js'
import Searchbar from '@/components/Searchbar.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import ImageDisplay from '@/components/ImageDisplay.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  // ------ page properties ------
  name: 'Datatable',
  components: {
    Searchbar,
    FormInput,
    FormSelect,
    ImageDisplay,
    ImageUploader,
    ImageUploader
},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    disableFirstLastPage: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    disableToolbar: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableFiltering: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableMultiTypeSearch: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableSort: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    searchType: {
      type: String,
      default: ''
    },
    searchTypeOptions: {
      type: Array,
      default: () => [],
    },
    tableEmptyText: {
      type: String,
      default: 'No Data'
    },
    enableRefresh: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    showTopSlot: {
      type: Boolean,
      default: true,
    },
    disableShadow: {
      type: Boolean,
      default: false,
    },
    selectedTableRow: {
      type: Array,
      default: () => [],
    },
    isInputTable: {
      type: Boolean,
      default: false
    },
    headerInputFields: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    processing() {
      return this.isLoading
    },
    hideDefaultHeader() {
      return false;
    },





    uploading() {
      return this.$store.getters.isLoading
    },

  },

  // ------ page variables ------
  data: () => ({
    tableInit: false,
    searchText: '',
    options: {},
    selectedRow: [],
    icon: {
      mdiEyeOutline,
      mdiCheck,
      mdiClose,
      mdiRefresh,
      mdiMagnify,
      mdiCalendarSearch,
      mdiTableArrowRight,
      mdiTrashCanOutline,
    },
  }),

  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
    selectedTableRow: {
      handler(val) {
        this.selectedRow = val;
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true;
    },
    clickActionView(id) {
      if (this.actionViewRouteLink) {
        this.$router.push({ name: this.actionViewRouteLink, params: { id: id } });
      } else {
        this.$emit('view-clicked', id);
      }
    },
    clickActionDelete(id) {
      this.$emit('delete-clicked', id);
    },
    clickClearAll() {
      this.searchText = '';
      this.$emit('all-search-cleared');
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    inputUpdate(id, key, value) {
      this.$emit('table-input-updated', {
        id, key, value
      });
    },
    previewImg(img) {
      if (this.$validate.DataValid(img)) {
        window.open(`${this.$mediaPath}${img}`);
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '檔案不存在',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    disabledInput(item, header) {
      if (header.itemInput && header.itemInput.disabled === true) {
        return true;
      }

      let disabled = false;
      const disableRelatedKeys = header.disableRelatedKeys;
      if (this.$validate.DataValid(item) && this.$validate.DataValid(disableRelatedKeys)) {
        for (const i in disableRelatedKeys) {
          if (this.$validate.DataValid(item[disableRelatedKeys[i]])) {
            disabled = true;
            break
          }
        }
      }
      return disabled;
    },
  },
}
</script>
