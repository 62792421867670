<template>
    <div class="image-upload-display">
        <div v-if="!$validate.DataValid(imageList)">N/A</div>

        <template v-for="(img, index) of imageList">
            <div :key="index">
                <template v-if="(typeof img === 'object') && img.image">
                    <span class="img-des" v-if="$validate.DataValid(img.remark)">{{ img.remark }}</span>
                    <v-img 
                        class="preview-img" 
                        :src="getImage(img.image)" 
                        :max-height="150" 
                        :max-width="250"
                        @click="previewImg(index, key)"
                    ></v-img>
                </template>
                <v-img v-else-if="(typeof img === 'string')" 
                    class="preview-img" 
                    :src="getImage(img)" 
                    :max-height="150"
                    :max-width="250" 
                    @click="previewImg(index, key)"
                ></v-img>
                <div v-else>N/A</div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ImageDisplay',
    props: {
        imageList: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        getImage(img) {
            if (img.indexOf('pdf') !== -1) {
                return require('@/assets/file-pdf-box.svg')
            } else if (img.indexOf('mp4') !== -1 || img.indexOf('webm') !== -1) {
                return require('@/assets/video.svg')
            }

            return this.$mediaPath + img;
        },

        previewImg(index) {
            if (this.$validate.DataValid(this.imageList[index])) {
                let img = '';
                if (typeof this.imageList[index] === 'object' && this.$validate.DataValid(this.imageList[index].image)) {
                    img = this.imageList[index].image;
                } else if (typeof this.imageList[index] === 'string') {
                    img = this.imageList[index];
                }

                if (img !== '') {
                    window.open(this.$mediaPath + img);
                    return;
                }
            }

            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: '档案不存在',
                type: 'error',
                refresh: false,
                redirect: '',
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.image-upload-display {
  display: flex;
  overflow-x: auto;
  align-items: flex-end;

  &>div:not(:first-child) {
    margin-left: 20px;
  }

  & .img-des {
    white-space: pre-wrap;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-width: 250px;
    -webkit-box-orient: vertical;
  }
}


.preview-img {
  &:hover {
    cursor: pointer;
  }
}
</style>